<template>

  <div :class="{sort: true, showed: showed, position: position}" ref="elSort">


    <div class="hnt" v-if="enablehint">
      {{ $t('universal_sort_sort_by') }}
    </div>

    <div class="ched" @click="handleToggle">

      <span v-if="enablename">{{ selectedOption.name ? selectedOption.name : (selectedOption.tr ? $t(selectedOption.tr) : '') }}</span>     
      <i class="icon-caret-down" v-if="selectedOption.icon == 'desc'"></i>
      <i class="icon-caret-up" v-else-if="selectedOption.icon == 'asc'"></i>
      <i class="icon-sort ic-sort" v-else></i>
    </div>
    
    <div class="opts">
      <template
        v-for="option in options"
        >
        <div 
        @click="handleClickOption(option)"
          :class="'opt' + (option.id == selected ? ' active ' : '')"
          >
          {{ option.name ? option.name : (option.tr ? $t(option.tr) : '') }}
          <i class="icon-caret-down" v-if="option.icon == 'desc'"></i>
          <i class="icon-caret-up" v-else-if="option.icon == 'asc'"></i>
        </div>        
      </template>
    </div>
 

  </div>
  
</template>

<script setup lang="ts">

import { onClickOutside } from '@vueuse/core'


interface PageProps {
  val?: string,
  align?: string,
  options?: Array<any>,
  enablehint?: boolean,
  enablename?: boolean
}

const props = withDefaults(defineProps<PageProps>(), {
  val: '',
  align: 'left',
  options: [],
  enablehint: true,
  enablename: true  
})


const emits = defineEmits(['choose'])


const position = ref(props.align);
const selected = ref(props.val);
const showed = ref(false);
const elSort = ref(null);

const selectedOption = computed(() => {
  var sel = {id: '', name: '', tr: ''};
  for(var option of props.options){
    if(option.id == selected.value) {
      sel = option;
    }
  }
  return sel;
})

const hide = () => {
  if(showed.value) 
    handleToggle();
}

const handleToggle = () => {
  showed.value = !showed.value;
}

const handleClickOption = (option) => {
  choose(option.id);
  handleToggle();
}

const choose = (id) => {
  selected.value = id;
  emits('choose', selected.value);
}
 


onClickOutside(elSort, (event) => {
  if(!showed.value) return;
  hide();
})



</script>

<style scoped>


.sort{
  position: relative;
  display: flex;
  align-content: center;
  color: var(--gd-dropdown-color);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 30px;
  justify-content: center;
  align-items: center;
}
.sort.right{
  justify-content: flex-end;
}
.sort .hnt{

}
.sort .ched{  
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
}
.sort .ic-sort{
  margin-left: 5px;
}
.sort .opts{
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  background-color: var(--gd-dropdown-back-color);
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 140px;

}
.sort.showed .opts{
  display: initial;
}

.sort .opts .opt{
  padding: 5px 15px;
  cursor: pointer;
}
.sort .opts .opt:hover{
  background-color: var(--gd-dropdown-back-active-color);
}
.sort .opts .opt.active{
  opacity: 0.5;
  pointer-events: none;
}
</style>
